import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { withDevtools, withStorageSync } from '@angular-architects/ngrx-toolkit';
import { GetStateData, IStateData } from '../shared/interfaces/state/state';
import { HttpClientDevPosDynamic, HttpClientDevPosSalesInvoice, ImportTableTypeEnum, IntegrationStatisticsDto } from '../shared/devpos.api';
import { selectSkipCache } from '../inventory/store/inventory.selectors';
import moment from 'moment/moment';
import { EXPIRE_API_SECONDS } from '../shared/constants/api-priority.seconds';
import { firstValueFrom, map, take, tap } from 'rxjs';
import { nswagCatchOperator } from '../shared/operators/nswag-catch-operator';
import { Store } from '@ngrx/store';
import { DevPosIdentifiersDto, HttpClientAlpha, HttpClientDevPos } from '../shared/nswag.api';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

type DEVPOS_STATE = {
  devPosStatistics: IStateData<IntegrationStatisticsDto[]>;
  identifiers: IStateData<DevPosIdentifiersDto[]>;
  alphaStatistics: IStateData<IntegrationStatisticsDto[]>;

  sync: {
    ids: IStateData<{ table: ImportTableTypeEnum; rowId: number }>[];
    all: IStateData<{ table: ImportTableTypeEnum }>[];
  };
  post: {
    ids: IStateData<{ table: ImportTableTypeEnum; rowId: number }>[];
  };

  loadedIds: {
    ImportTableType: ImportTableTypeEnum;
    RowId: number[];
  }[];
};

const INITIAL_DEVPOS_STATE: DEVPOS_STATE = {
  devPosStatistics: {
    data: [],
    loading: false,
    lastUpdatedDate: undefined,
  },
  identifiers: {
    data: [],
    loading: false,
    lastUpdatedDate: undefined,
  },
  alphaStatistics: {
    data: [],
    loading: false,
    lastUpdatedDate: undefined,
  },
  sync: {
    ids: [],
    all: [],
  },
  post: {
    ids: [],
  },
  loadedIds: [],
};

export const DevPosStore = signalStore(
  { providedIn: 'root' },
  withState(INITIAL_DEVPOS_STATE),
  withComputed(state => ({
    validDevPosStatistics: computed(() => GetStateData(state.devPosStatistics(), moment(), EXPIRE_API_SECONDS.INTEGRATION, false)),
    validIdentifiers: computed(() => GetStateData(state.identifiers(), moment(), EXPIRE_API_SECONDS.INTEGRATION, false)),
    validAlphaStatistics: computed(() => GetStateData(state.alphaStatistics(), moment(), EXPIRE_API_SECONDS.INTEGRATION, false)),
  })),
  withMethods(
    (
      store,
      httpClientDevPosDynamic = inject(HttpClientDevPosDynamic),
      httpClientDevPosSiDynamic = inject(HttpClientDevPosSalesInvoice),
      httpClientAlpha = inject(HttpClientAlpha),
      httpClientDevpos = inject(HttpClientDevPos),
      toast = inject(ToastrService),
      translate = inject(TranslateService),
      oldStore = inject(Store),
    ) => ({
      loadDevPosIdentifiers: async (force_skip: boolean = false) => {
        patchState(store, { ...store.devPosStatistics, identifiers: { ...store.identifiers(), loading: true } });

        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const existingData = GetStateData(store.identifiers(), moment(), EXPIRE_API_SECONDS.INTEGRATION, skip_cache());
          if (existingData) {
            patchState(store, { ...store.devPosStatistics, identifiers: { ...store.identifiers(), loading: false } });
            return;
          }
        }

        const newData = await firstValueFrom(httpClientDevpos.identifiersView().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            identifiers: {
              data: [],
              loading: false,
              lastUpdatedDate: undefined,
            },
          });
          return;
        }

        patchState(store, {
          identifiers: {
            data: newData.data,
            loading: false,
            lastUpdatedDate: moment(),
          },
        });
      },

      loadDevPosStatistics: async (force_skip: boolean = false) => {
        patchState(store, { ...store.identifiers, devPosStatistics: { ...store.devPosStatistics(), loading: true } });

        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const existingData = GetStateData(store.devPosStatistics(), moment(), EXPIRE_API_SECONDS.INTEGRATION, skip_cache());
          if (existingData) {
            patchState(store, { ...store.identifiers, devPosStatistics: { ...store.devPosStatistics(), loading: false } });
            return;
          }
        }

        const newData = await firstValueFrom(httpClientDevPosDynamic.devPostStatistics().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            ...store.identifiers,
            devPosStatistics: {
              data: [],
              loading: false,
              lastUpdatedDate: undefined,
            },
          });
          return;
        }

        patchState(store, {
          ...store.identifiers,
          devPosStatistics: {
            data: newData.data,
            loading: false,
            lastUpdatedDate: moment(),
          },
        });
      },
      loadAlphaStatistics: async (force_skip: boolean = false) => {
        patchState(store, { ...store.alphaStatistics, alphaStatistics: { ...store.alphaStatistics(), loading: true } });

        if (!force_skip) {
          const skip_cache = oldStore.selectSignal(selectSkipCache(moment()));
          const existingData = GetStateData(store.alphaStatistics(), moment(), EXPIRE_API_SECONDS.INTEGRATION, skip_cache());
          if (existingData) {
            patchState(store, { ...store.alphaStatistics, alphaStatistics: { ...store.alphaStatistics(), loading: false } });
            return;
          }
        }

        const newData = await firstValueFrom(httpClientAlpha.alphaImportStatistics().pipe(nswagCatchOperator(), take(1)));
        if (!newData.succeeded || !newData.data || newData.data.length === 0) {
          patchState(store, {
            ...store.alphaStatistics,
            alphaStatistics: {
              data: [],
              loading: false,
              lastUpdatedDate: undefined,
            },
          });
          return;
        }

        patchState(store, {
          ...store.alphaStatistics,
          alphaStatistics: {
            data: newData.data,
            loading: false,
            lastUpdatedDate: moment(),
          },
        });
      },

      syncDevPosAll: async (importTableType: ImportTableTypeEnum) => {
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const updated = await firstValueFrom(
          httpClientDevPosDynamic.dynamicDevPosValidate(importTableType, undefined).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncDevPosRow: async (importTableType: ImportTableTypeEnum, id: number) => {
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const updated = await firstValueFrom(
          httpClientDevPosDynamic.dynamicDevPosValidate(importTableType, id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },

      syncAlphaAll: async (importTableType: ImportTableTypeEnum) => {
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const updated = await firstValueFrom(
          httpClientAlpha.dynamicAlphaSync(importTableType, undefined).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncAlphaRow: async (importTableType: ImportTableTypeEnum, id: number) => {
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const updated = await firstValueFrom(
          httpClientAlpha.dynamicAlphaSync(importTableType, id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded && !!res.data),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },

      unsetSyncAll: (tableTypeEnum: ImportTableTypeEnum) => {
        const allIndex = store.sync.all().findIndex(x => x.data?.table === tableTypeEnum);
        if (allIndex !== -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });

        const loadedIndex = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (loadedIndex !== -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, loadedIndex),
              {
                ...store.loadedIds()[loadedIndex],
                RowId: store.loadedIds()[loadedIndex].RowId.filter((_, i) => i % 2 === 0),
              },
              ...store.loadedIds().slice(loadedIndex + 1),
            ],
          });
      },
      unsetSyncSingleRow: (tableTypeEnum: ImportTableTypeEnum, rowId: number) => {
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === tableTypeEnum && x.data?.rowId === rowId);
        if (idIndex !== -1)
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });

        const loadedIndex = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (loadedIndex !== -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, loadedIndex),
              {
                ...store.loadedIds()[loadedIndex],
                RowId: store.loadedIds()[loadedIndex].RowId.filter(x => x !== rowId),
              },
              ...store.loadedIds().slice(loadedIndex + 1),
            ],
          });
      },

      unsetPostRow: (tableTypeEnum: ImportTableTypeEnum, rowId: number | undefined, success: boolean) => {
        const idIndex = store.post.ids().findIndex(x => x.data?.table === tableTypeEnum && x.data?.rowId === rowId);
        if (idIndex !== -1)
          patchState(store, {
            post: {
              ids: [...store.post.ids().slice(0, idIndex), ...store.post.ids().slice(idIndex + 1)],
            },
          });

        const loadedIndex = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (loadedIndex !== -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, loadedIndex),
              {
                ...store.loadedIds()[loadedIndex],
                RowId: store.loadedIds()[loadedIndex].RowId.filter(x => x !== rowId),
              },
              ...store.loadedIds().slice(loadedIndex + 1),
            ],
          });
      },

      syncDevPosAllInvoices: async () => {
        const importTableType = ImportTableTypeEnum.DevPosSalesInvoice;
        const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
        if (allIndex === -1)
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all(), { data: { table: importTableType }, loading: true, lastUpdatedDate: moment() }],
            },
          });
        else
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [
                ...store.sync.all().slice(0, allIndex),
                { ...store.sync.all()[allIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.all().slice(allIndex + 1),
              ],
            },
          });

        const updated = await firstValueFrom(
          httpClientDevPosSiDynamic.validateAndPostSalesInvoice().pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success'));
            }),
          ),
        );

        if (!updated) {
          const allIndex = store.sync.all().findIndex(x => x.data?.table === importTableType);
          patchState(store, {
            sync: {
              ids: store.sync.ids(),
              all: [...store.sync.all().slice(0, allIndex), ...store.sync.all().slice(allIndex + 1)],
            },
          });
        }
      },
      syncDevPosRowInvoice: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosSalesInvoice;
        const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
              all: store.sync.all(),
            },
          });
        else
          patchState(store, {
            sync: {
              ids: [
                ...store.sync.ids().slice(0, idIndex),
                { ...store.sync.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.sync.ids().slice(idIndex + 1),
              ],
              all: store.sync.all(),
            },
          });

        const updated = await firstValueFrom(
          httpClientDevPosSiDynamic.validateAndPostSalesInvoice(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            tap(res => {
              if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            }),
          ),
        );

        if (!updated) {
          const idIndex = store.sync.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            sync: {
              ids: [...store.sync.ids().slice(0, idIndex), ...store.sync.ids().slice(idIndex + 1)],
              all: store.sync.all(),
            },
          });
        }
      },

      postDevPosRowInvoice: async (id: number) => {
        const importTableType = ImportTableTypeEnum.DevPosSalesInvoice;
        const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
        if (idIndex === -1)
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids(),
                {
                  data: { table: importTableType, rowId: id },
                  loading: true,
                  lastUpdatedDate: moment(),
                },
              ],
            },
          });
        else
          patchState(store, {
            post: {
              ids: [
                ...store.post.ids().slice(0, idIndex),
                { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
                ...store.post.ids().slice(idIndex + 1),
              ],
            },
          });

        patchState(store, {
          post: {
            ids: [
              ...store.post.ids().slice(0, idIndex),
              { ...store.post.ids()[idIndex], loading: true, lastUpdatedDate: moment() },
              ...store.post.ids().slice(idIndex + 1),
            ],
          },
        });

        const updated = await firstValueFrom(
          httpClientDevPosSiDynamic.queuePostDevPosSalesInvoice(id).pipe(
            nswagCatchOperator(),
            take(1),
            map(res => !!res.succeeded),
            // tap(res => {
            //   if (res) toast.success(translate.instant('integrations.success_row', { id: id }));
            // }),
          ),
        );

        if (!updated) {
          const idIndex = store.post.ids().findIndex(x => x.data?.table === importTableType && x.data.rowId === id);
          patchState(store, {
            post: {
              ids: [...store.post.ids().slice(0, idIndex), ...store.post.ids().slice(idIndex + 1)],
            },
          });
        }
      },

      setIds: (tableTypeEnum: ImportTableTypeEnum, ids: number[]) => {
        const index = store.loadedIds().findIndex(x => x.ImportTableType === tableTypeEnum);
        if (index === -1)
          patchState(store, {
            loadedIds: [
              ...store.loadedIds(),
              {
                ImportTableType: tableTypeEnum,
                RowId: ids,
              },
            ],
          });
        else
          patchState(store, {
            loadedIds: [
              ...store.loadedIds().slice(0, index),
              {
                ImportTableType: tableTypeEnum,
                RowId: ids,
              },
              ...store.loadedIds().slice(index + 1),
            ],
          });
      },
      clearDevPosCache: () => {
        patchState(store, INITIAL_DEVPOS_STATE);
      },
    }),
  ),
  withStorageSync({
    key: 'DEVPOS_STATE',
    autoSync: true,
    select: state =>
      <Partial<DEVPOS_STATE>>{
        devPosStatistics: state.devPosStatistics,
        alphaStatistics: state.alphaStatistics,
        sync: state.sync,
        post: state.post,
      },
    parse: (stateString: string) => {
      try {
        return JSON.parse(stateString) as DEVPOS_STATE;
      } catch (e) {
        return INITIAL_DEVPOS_STATE;
      }
    },
    stringify: state => JSON.stringify(state),
    storage: () => localStorage, // factory to select storage to sync with
  }),
  withDevtools('devPosStore'),
);
